<template>
  <div class="nav-custom">
    <template
      v-for="(item, i) in dropdownMenuItems"
    >
      <template v-if="item.show">
        <CLink
          v-if="item.name"
          :key="i"
          :to="{ name: item.name }"
          class="nav-link"
        >
          <CIcon v-if="item.iconName" :name="item.iconName"/>
          <div class="nav-item-text">
            {{ item.i18nKey ? $t(item.i18nKey) : (item.label || '') }}
          </div>
        </CLink>
        <CLink
          v-else
          :key="i"
          :href="$t(item.path)"
          target="_blank"
          class="nav-link"
        >
          <CIcon v-if="item.iconName" :name="item.iconName"/>
          <div class="nav-item-text">
            {{ item.i18nKey ? $t(item.i18nKey) : (item.label || '') }}
          </div>
        </CLink>
      </template>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {dropdownMenuItems} from "@/assets/js/dropdownMenuItems";

export default {
  name: "LinkGroup",
  data() {
    return {
      dropdownMenuItems: dropdownMenuItems()
    }
  },
  computed: {
    ...mapGetters({
      isDesktopXXL: 'mediaQuery/isDesktopXXL',
    }),
  },
  watch: {
    '$route'() {
      this.$nextTick(this.scrollToCurrentLink)
    }
  },
  mounted() {
    this.$nextTick(() => setTimeout(this.scrollToCurrentLink, 500))
    this.$root.$on('dropdownMenuUpdate', () => {
      this.dropdownMenuItems = dropdownMenuItems()
    })
  },
  methods: {
    scrollToCurrentLink() {
      if (!this.isDesktopXXL) {
        const currentLink = this.$el.querySelector('a.nav-link.router-link-active')

        if (currentLink) {
          currentLink.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
            block: 'center',
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-custom {
  flex-shrink: 0;
  display: flex;
  overflow: auto;

  &::-webkit-scrollbar {
    background: var(--base-card-bg);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  .nav-link {
    color: var(--dark-contrast);
    white-space: nowrap;
    display: flex;
    gap: 0.5rem;

    &.router-link-active {
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      background: var(--base-card-bg);
    }
  }
}

@media (min-width: 1440px) {
  .nav-custom {
    position: absolute;
    left: 0.75rem;
    display: flex;
    z-index: 11;
    flex-direction: column;
    max-width: 1.875rem;
    background: var(--base-card-bg);
    border-radius: 0.6rem;
    box-shadow: 0 0 5px 1px var(--base-card-bg);
    transition: max-width 250ms ease-out;
    gap: 0.3rem;
    overflow: visible;

    &:hover {
      max-width: 13.7rem;

      .nav-item-text {
        opacity: 1;
        visibility: visible;
      }
    }

    .nav-item-text {
      transition: visibility 250ms ease-out;
      visibility: hidden;
      opacity: 0;
      font-size: 12px;
      white-space: nowrap;
    }

    .nav-link {
      color: var(--dark-contrast);
      border-radius: 0.6rem;
      transition: all 250ms ease-out;
      padding: 0.3rem 0.5rem;
      display: flex;
      gap: 0.5rem;

      & .c-icon {
        flex-shrink: 0;
      }

      &.router-link-active {
        background: var(--primary);
      }

      &:hover {
        background: var(--primary);
      }
    }
  }
}

@media (min-width: 1800px) {
  .nav-custom {
    max-width: fit-content;

    .nav-item-text {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
