var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-custom" },
    [
      _vm._l(_vm.dropdownMenuItems, function (item, i) {
        return [
          item.show
            ? [
                item.name
                  ? _c(
                      "CLink",
                      {
                        key: i,
                        staticClass: "nav-link",
                        attrs: { to: { name: item.name } },
                      },
                      [
                        item.iconName
                          ? _c("CIcon", { attrs: { name: item.iconName } })
                          : _vm._e(),
                        _c("div", { staticClass: "nav-item-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.i18nKey
                                  ? _vm.$t(item.i18nKey)
                                  : item.label || ""
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "CLink",
                      {
                        key: i,
                        staticClass: "nav-link",
                        attrs: { href: _vm.$t(item.path), target: "_blank" },
                      },
                      [
                        item.iconName
                          ? _c("CIcon", { attrs: { name: item.iconName } })
                          : _vm._e(),
                        _c("div", { staticClass: "nav-item-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.i18nKey
                                  ? _vm.$t(item.i18nKey)
                                  : item.label || ""
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }